import React from 'react'
import { useQueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../Apis/config'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import EditProduct from '../components/Dashboard/Product/EditProduct';
const EditPage = () => {
    const {pid,uid}=useParams()
    const ProductEdit = async () => {
        const { data } = await axios.get(`${BASE_URL}/posts/${pid}/edit?user_id=${uid}`);
        return data;
      };
    const queryClient = useQueryClient();
    const { data, error, isLoading } = useQuery({
      queryKey: ["Edit"],
      queryFn: ProductEdit
    });
    if (isLoading) {
        return <div>Loading...</div>}
  return (
    <>
    <div  className="body_area grid_main">
    {data&&<EditProduct data={data}></EditProduct>}
    </div>        
    </>
   
  )
}

export default EditPage
