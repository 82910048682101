export const handleShare = async () => {
    const url = 'https://buynsell.mchamp.xyz/';
    const title = 'buynsell';
    const text = 'I found this amazing site and thought you might like it.';
    if (navigator.share) {
        try {
          await navigator.share({
            title: title || document.title,
            text: text || '',
            url: url || window.location.href,
          });
          console.log('Share was successful.');
        } catch (error) {
          console.error('Share failed:', error);
        }
      } else {
        // Fallback
        window.prompt('Copy this URL and share it:', url);
      }
  };
