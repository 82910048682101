import React from 'react'

const Account = () => {
  return (
   <>
   <div className="col-lg-9 col-md-12">
  <div className="dashboard-box margin-top-0">
    {/* Headline */}
    <div className="headline">
      <h3>
        <i className="icon-feather-settings" /> Account Setting
      </h3>
    </div>
    <div className="content with-padding">
      <form
        method="post"
        action="https://buynsell.mchamp.xyz/settings/edit-profile"
        acceptCharset="UTF-8"
        encType="multipart/form-data"
      >
        <input
          type="hidden"
          name="_token"
          defaultValue="1uyMuVPvPCsbNHzA48KbCGG0b7UpXhEsofymRbn8"
        />{" "}
        <div className="row">
          <div className="col-md-12">
            <div className="submit-field">
              <h5>Avatar</h5>
              <div className="d-flex align-items-center">
                <img
                  id="avatar-img"
                  className="margin-right-20"
                  src="https://buynsell.mchamp.xyz/storage/profile/default_user.png"
                  width={90}
                />
                <div className="BrowseButton">
                  <input
                    className="BrowseButton-input"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    id="upload"
                    name="avatar"
                    onchange="readImageURL(this, 'avatar-img');"
                  />
                  <label
                    className="BrowseButton-button ripple-effect"
                    htmlFor="upload"
                  >
                    Upload Avatar
                  </label>
                  <span className="BrowseButton-file-name">
                    Use 150x150px for better use
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="submit-field">
              <h5>Username *</h5>
              <div className="input-with-icon-left">
                <i className="la la-user" />
                <input
                  type="text"
                  className="with-border"
                  id="username"
                  name="username"
                  defaultValue="Avd12"
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="submit-field">
              <h5>Email Address *</h5>
              <div className="input-with-icon-left">
                <i className="la la-envelope" />
                <input
                  type="text"
                  className="with-border"
                  id="email"
                  name="email"
                  defaultValue="avdhesh.kumar@mchamp.com"
                  required=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="submit-field">
              <h5>New Password</h5>
              <input
                type="password"
                id="new_password"
                name="new_password"
                className="with-border"
                min={6}
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="submit-field">
              <h5>Confirm New Password</h5>
              <input
                type="password"
                id="new_password_confirmation"
                name="new_password_confirmation"
                className="with-border"
                min={6}
              />
            </div>
          </div>
        </div>
        <button type="submit" name="submit" className="button ripple-effect">
          Save Changes
        </button>
      </form>
    </div>
  </div>
  <div className="dashboard-box">
    <div className="headline">
      <h3>
        <i className="icon-material-outline-description" /> Billing Details
      </h3>
    </div>
    <div className="content">
      <div className="content with-padding">
        <div className="notification notice">
          These details will be used in invoice and payments.
        </div>
        <form
          method="post"
          action="https://buynsell.mchamp.xyz/settings/edit-billing"
          acceptCharset="UTF-8"
        >
          <input
            type="hidden"
            name="_token"
            defaultValue="1uyMuVPvPCsbNHzA48KbCGG0b7UpXhEsofymRbn8"
          />{" "}
          <div className="submit-field">
            <h5>Type</h5>
            <select
              name="billing_details_type"
              id="billing_details_type"
              className="with-border selectpicker"
              required=""
            >
              <option value="personal" selected="">
                Personal
              </option>
              <option value="business">Business</option>
            </select>
          </div>
          <div className="submit-field billing-tax-id">
            <h5>Tax ID</h5>
            <input
              type="text"
              id="billing_tax_id"
              name="billing_tax_id"
              className="with-border"
              defaultValue=""
            />
          </div>
          <div className="submit-field">
            <h5>Name *</h5>
            <input
              type="text"
              id="billing_name"
              name="billing_name"
              className="with-border"
              defaultValue=""
              required=""
            />
          </div>
          <div className="submit-field">
            <h5>Address *</h5>
            <input
              type="text"
              id="billing_address"
              name="billing_address"
              className="with-border"
              defaultValue=""
              required=""
            />
          </div>
          <div className="submit-field select-wrapper">
            <h5>Country *</h5>
            <select
              name="billing_country"
              id="billing_country"
              className="with-border selectpicker"
              data-live-search="true"
              data-size={5}
              required=""
            >
              <option value="" disabled="" selected="">
                Choose
              </option>
              <option value="AD">Andorra</option>
              <option value="AE">United Arab Emirates</option>
              <option value="AF">Afghanistan</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AI">Anguilla</option>
              <option value="AL">Albania</option>
              <option value="AM">Armenia</option>
              <option value="AN">Netherlands Antilles</option>
              <option value="AO">Angola</option>
              <option value="AR">Argentina</option>
              <option value="AS">American Samoa</option>
              <option value="AT">Austria</option>
              <option value="AU">Australia</option>
              <option value="AW">Aruba</option>
              <option value="AX">Aland Islands</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BB">Barbados</option>
              <option value="BD">Bangladesh</option>
              <option value="BE">Belgium</option>
              <option value="BF">Burkina Faso</option>
              <option value="BG">Bulgaria</option>
              <option value="BH">Bahrain</option>
              <option value="BI">Burundi</option>
              <option value="BJ">Benin</option>
              <option value="BL">Saint Barthelemy</option>
              <option value="BM">Bermuda</option>
              <option value="BN">Brunei</option>
              <option value="BO">Bolivia</option>
              <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
              <option value="BR">Brazil</option>
              <option value="BS">Bahamas</option>
              <option value="BT">Bhutan</option>
              <option value="BV">Bouvet Island</option>
              <option value="BW">Botswana</option>
              <option value="BY">Belarus</option>
              <option value="BZ">Belize</option>
              <option value="CA">Canada</option>
              <option value="CC">Cocos Islands</option>
              <option value="CD">Democratic Republic of the Congo</option>
              <option value="CF">Central African Republic</option>
              <option value="CG">Republic of the Congo</option>
              <option value="CH">Switzerland</option>
              <option value="CI">Ivory Coast</option>
              <option value="CK">Cook Islands</option>
              <option value="CL">Chile</option>
              <option value="CM">Cameroon</option>
              <option value="CN">China</option>
              <option value="CO">Colombia</option>
              <option value="CR">Costa Rica</option>
              <option value="CU">Cuba</option>
              <option value="CV">Cape Verde</option>
              <option value="CW">Curacao</option>
              <option value="CX">Christmas Island</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DE">Germany</option>
              <option value="DJ">Djibouti</option>
              <option value="DK">Denmark</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="DZ">Algeria</option>
              <option value="EC">Ecuador</option>
              <option value="EE">Estonia</option>
              <option value="EG">Egypt</option>
              <option value="EH">Western Sahara</option>
              <option value="ER">Eritrea</option>
              <option value="ES">Spain</option>
              <option value="ET">Ethiopia</option>
              <option value="FI">Finland</option>
              <option value="FJ">Fiji</option>
              <option value="FK">Falkland Islands</option>
              <option value="FM">Micronesia</option>
              <option value="FO">Faroe Islands</option>
              <option value="FR">France</option>
              <option value="GA">Gabon</option>
              <option value="GD">Grenada</option>
              <option value="GE">Georgia</option>
              <option value="GF">French Guiana</option>
              <option value="GG">Guernsey</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GL">Greenland</option>
              <option value="GM">Gambia</option>
              <option value="GN">Guinea</option>
              <option value="GP">Guadeloupe</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="GR">Greece</option>
              <option value="GT">Guatemala</option>
              <option value="GU">Guam</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HK">Hong Kong</option>
              <option value="HN">Honduras</option>
              <option value="HR">Croatia</option>
              <option value="HT">Haiti</option>
              <option value="HU">Hungary</option>
              <option value="ID">Indonesia</option>
              <option value="IE">Ireland</option>
              <option value="IL">Israel</option>
              <option value="IM">Isle of Man</option>
              <option value="IN">India</option>
              <option value="IQ">Iraq</option>
              <option value="IR">Iran</option>
              <option value="IS">Iceland</option>
              <option value="IT">Italy</option>
              <option value="JE">Jersey</option>
              <option value="JM">Jamaica</option>
              <option value="JO">Jordan</option>
              <option value="JP">Japan</option>
              <option value="KE">Kenya</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="KH">Cambodia</option>
              <option value="KI">Kiribati</option>
              <option value="KM">Comoros</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="KP">North Korea</option>
              <option value="KR">South Korea</option>
              <option value="KW">Kuwait</option>
              <option value="KY">Cayman Islands</option>
              <option value="KZ">Kazakhstan</option>
              <option value="LA">Laos</option>
              <option value="LB">Lebanon</option>
              <option value="LC">Saint Lucia</option>
              <option value="LI">Liechtenstein</option>
              <option value="LK">Sri Lanka</option>
              <option value="LR">Liberia</option>
              <option value="LS">Lesotho</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="LV">Latvia</option>
              <option value="LY">Libya</option>
              <option value="MA">Morocco</option>
              <option value="MC">Monaco</option>
              <option value="MD">Moldova</option>
              <option value="ME">Montenegro</option>
              <option value="MF">Saint Martin</option>
              <option value="MG">Madagascar</option>
              <option value="MH">Marshall Islands</option>
              <option value="MK">Macedonia</option>
              <option value="ML">Mali</option>
              <option value="MM">Myanmar</option>
              <option value="MN">Mongolia</option>
              <option value="MO">Macao</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MS">Montserrat</option>
              <option value="MT">Malta</option>
              <option value="MU">Mauritius</option>
              <option value="MV">Maldives</option>
              <option value="MW">Malawi</option>
              <option value="MX">Mexico</option>
              <option value="MY">Malaysia</option>
              <option value="MZ">Mozambique</option>
              <option value="NA">Namibia</option>
              <option value="NC">New Caledonia</option>
              <option value="NE">Niger</option>
              <option value="NF">Norfolk Island</option>
              <option value="NG">Nigeria</option>
              <option value="NI">Nicaragua</option>
              <option value="NL">Netherlands</option>
              <option value="NO">Norway</option>
              <option value="NP">Nepal</option>
              <option value="NR">Nauru</option>
              <option value="NU">Niue</option>
              <option value="NZ">New Zealand</option>
              <option value="OM">Oman</option>
              <option value="PA">Panama</option>
              <option value="PE">Peru</option>
              <option value="PF">French Polynesia</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PH">Philippines</option>
              <option value="PK">Pakistan</option>
              <option value="PL">Poland</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="PN">Pitcairn</option>
              <option value="PR">Puerto Rico</option>
              <option value="PS">Palestine</option>
              <option value="PT">Portugal</option>
              <option value="PW">Palau</option>
              <option value="PY">Paraguay</option>
              <option value="QA">Qatar</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RS">Serbia</option>
              <option value="RU">Russia</option>
              <option value="RW">Rwanda</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SC">Seychelles</option>
              <option value="SD">Sudan</option>
              <option value="SE">Sweden</option>
              <option value="SG">Singapore</option>
              <option value="SH">Saint Helena</option>
              <option value="SI">Slovenia</option>
              <option value="SK">Slovakia</option>
              <option value="SL">Sierra Leone</option>
              <option value="SM">San Marino</option>
              <option value="SN">Senegal</option>
              <option value="SO">Somalia</option>
              <option value="SR">Suriname</option>
              <option value="SS">South Sudan</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SV">El Salvador</option>
              <option value="SX">Sint Maarten</option>
              <option value="SY">Syria</option>
              <option value="SZ">Swaziland</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TD">Chad</option>
              <option value="TG">Togo</option>
              <option value="TH">Thailand</option>
              <option value="TJ">Tajikistan</option>
              <option value="TK">Tokelau</option>
              <option value="TL">East Timor</option>
              <option value="TM">Turkmenistan</option>
              <option value="TN">Tunisia</option>
              <option value="TO">Tonga</option>
              <option value="TR">Turkey</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TV">Tuvalu</option>
              <option value="TW">Taiwan</option>
              <option value="TZ">Tanzania</option>
              <option value="UA">Ukraine</option>
              <option value="UG">Uganda</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VA">Vatican</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="VE">Venezuela</option>
              <option value="VG">British Virgin Islands</option>
              <option value="VI">U.S. Virgin Islands</option>
              <option value="VN">Vietnam</option>
              <option value="VU">Vanuatu</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="WS">Samoa</option>
              <option value="XK">Kosovo</option>
              <option value="YE">Yemen</option>
              <option value="YT">Mayotte</option>
              <option value="ZA">South Africa</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="submit-field">
                <h5>City *</h5>
                <input
                  type="text"
                  id="billing_city"
                  name="billing_city"
                  className="with-border"
                  defaultValue=""
                  required=""
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="submit-field">
                <h5>State *</h5>
                <input
                  type="text"
                  id="billing_state"
                  name="billing_state"
                  className="with-border"
                  defaultValue=""
                  required=""
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="submit-field">
                <h5>Postal Code *</h5>
                <input
                  type="text"
                  id="billing_zipcode"
                  name="billing_zipcode"
                  className="with-border"
                  defaultValue=""
                  required=""
                />
              </div>
            </div>
          </div>
          <button type="submit" name="submit" className="button ripple-effect">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  </div>
  {/* <center>
    <div className="google-ads-728x90 margin-top-30 margin-bottom-30 my-4">
      <img
        src="https://via.placeholder.com/720x90"
        width="100%"
        height="100%"
      />
    </div>
  </center> */}
</div>

   </>
  )
}

export default Account
