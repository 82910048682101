import React, { useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import chat from '../../../images/chat.svg'
import user from '../../../images/user.png'
import Membership from '../../../images/membership.svg'
import Edit from '../../../images/edit.svg'
import timer from '../../../images/timer.svg'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../Apis/config'
import axios from 'axios';
import DashboardAdsContainer from '../DashboardAdsContainer/index';
import Empty from '../../../Pages/Empty'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const DashboardLayout = () => {
    const id = cookies.get('Id');
    const queryClient = useQueryClient();
    // const id = queryClient.getQueryData(['Id']);
    const MyListing = async () => {
        const { data } = await axios.get(`${BASE_URL}/posts/my-listing/?user_id=${id}`);
        return data.result.posts.data;
    };
    const { data, error, isLoading } = useQuery({
        queryKey: ["Listing"],
        queryFn: MyListing
    });
    const location = useLocation();
    const locationArray = location.pathname.split('/');
    const endPath = locationArray[locationArray.length - 1];
    const [value, setValue] = useState('Active')
    const res = (val) => {
        setValue(val)
    }
    const navigate = useNavigate()
    const redirect = (id) => {
        navigate(`/EditProfile/${id}`)
    }
    if (isLoading) {
        return <div>Loading...</div>;
    }
    function formatContent(content) {
        return content.length > 4 ? content.slice(-4) : content;
    }
    return (
        <>
            {!(data) && <div>no data</div>}
            {data && <div className="body_area grid_main">
                <div className='left_a'>
                    <div className="cont_user mt_10 d_flex">
                        <div className="d_flex gap">
                            <img src={user} alt="user" />
                            <div className="">
                                {data[0] && <p className="ft_18 robot_medium d_black ">{data[0].user.name}</p>
                                }
                                {!data[0] && <p className="ft_18 robot_medium d_black ">Buynsell User</p>

                                }
                                {!data[0] && <p className="ft_14 roboto_regular d_lblack d_flex">
                                    Username: Buynsell
                                </p>

                                }                     {data[0] && <p className="ft_14 roboto_regular d_lblack d_flex line_5">
                                    Username:   {formatContent(data[0].user.username)}**
                                </p>}
                                {/* <p className="ft_14 roboto_regular d_lblack  d_flex">
                                    Membership :
                                    <img src={Membership} alt="views" />
                                    Free Plan
                                </p> */}
                            </div>
                        </div>
                        <button className="pr_btn roboto_regular  ft_14" onClick={() => redirect()}>
                            {/* <img src={Edit} alt="Login" /> */}
                            Edit
                        </button>
                    </div>

                    {/* <div class="d_flex mt_10">
                        <p className="ft_14 roboto_regular d_lblack  d_flex">
                            <img src={timer} alt="views" />
                            Favorite Listings: 0
                        </p>
                        <p className="ft_14 roboto_regular d_lblack  d_flex">
                            <img src={timer} alt="views" />
                            My Listings:0
                        </p>
                    </div> */}
                    <p class="ft_18 roboto_medium mt_10">My Listing</p>
                    <div className="d_flex mt_10">
                        <a onClick={() => res('Active')} className={` ft_14 location_btn  roboto_regular d_blue active_ad ${value == 'Active' ? 'active_tab' : ''}`} data-discover="true">Active</a>
                        <a onClick={() => res('Pending')} className={` ft_14 location_btn  roboto_regular d_blue pending ${value == 'Pending' ? 'active_tab2' : ''}`} data-discover="true">Pending</a>
                        <a onClick={() => res('Expired')} className={` ft_14 location_btn  roboto_regular d_blue expired ${value == 'Expired' ? 'active_tab3' : ''}`} data-discover="true">Expired</a>
                        <a onClick={() => res('Rejected')} className={` ft_14 location_btn  roboto_regular d_blue rejected ${value == 'Rejected' ? 'active_tab4' : ''}`} data-discover="true">Rejected</a>
                    </div>
                    {data && data.map((ele, i) => {
                        return (
                            <>
                                {ele.status == 'active' && value == 'Active' && <DashboardAdsContainer status={value} css={'active_ad'} price={ele.price} title={ele.title} location={ele.location} date={ele.expire_date} pid={ele.id} uid={ele.user_id} pic={ele.images} />}
                                {ele.status == 'pending' && value == 'Pending' && <DashboardAdsContainer status={value} css={'pending'} price={ele.price} title={ele.title} location={ele.location} date={ele.expire_date} pid={ele.id} uid={ele.user_id} pic={ele.images} />}
                                {ele.status == 'expired' && value == 'Expired' && <DashboardAdsContainer status={value} css={'expired'} price={ele.price} title={ele.title} location={ele.location} date={ele.expire_date} pid={ele.id} uid={ele.user_id}  pic={ele.images}/>}
                                {ele.status == 'rejected' && value == 'Rejected' && <DashboardAdsContainer status={value} css={'rejected'} price={ele.price} title={ele.title} location={ele.location} date={ele.expire_date} pid={ele.id} uid={ele.user_id}  pic={ele.images}/>}
                            </>

                        )
                    })
                    }
                </div>
                <div className='right_a'></div>
            </div>}
        </>
    )
}

export default DashboardLayout