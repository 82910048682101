import React from 'react'
import { useQueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../Apis/config'
import M from "materialize-css/dist/js/materialize.min.js";
import { useEffect, useState } from 'react'
import Camera from '../../../images/camera.svg'
import Add from '../../../images/add.svg'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
const EditProduct = (props) => {
    // const {pid,uid}=useParams()
    // const ProductEdit = async () => {
    //     const { data } = await axios.get(`${BASE_URL}/posts/${pid}/edit?user_id=${uid}`);
    //     return data;
    //   };
    // const queryClient = useQueryClient();
    // const { data, error, isLoading } = useQuery({
    //   queryKey: ["Edit"],
    //   queryFn: ProductEdit
    // });
    // queryClient.setQueryData(['Edit'], data);
    const [user_id,Setuser_id]=useState(props.data.result.post.user_id)
    const [category,Setcategory]=useState(props.data.result.post.cat)
    const [sub_category,Setsub_category]=useState('41')
    const [title,Settitle]=useState(props.data.result.post.title)
    const [description,Setdescription]=useState(props.data.result.post.description)
    const [image,Setimage]=useState(props.data.result.post.images)
    const [price,Setprice]=useState(props.data.result.post.price)
    const [phone,Setphone]=useState(props.data.result.post.phone)
    const [city,Setcity]=useState(props.data.result.post.city_id)
    const [location,Setlocation]=useState(props.data.result.post.location)
    const [latitude,Setlatitude]=useState('36.6000')
    const [longitude,Setlongitude]=useState('-121.89000 ')      
    const [tags,Settags]=useState(props.data.result.post.tag)  
    const [make_premium,Setmake_premium]=useState(props.data.result.post.featured)
    const [agree_for_term,Setagree_for_term]=useState('0')
    const [negotiable,Setnegotiable]=useState(props.data.result.post.negotiable)
    // if(data){
    //     Setcategory(data.result.post.category_id)
    //     Settitle(data.result.post.title)
    //     Setdescription(data.result.post.description)
    //     Setprice(data.result.post.price)
    //     Setphone(data.result.post.phone)
    //     Setcity(data.result.post.city_id)
    //     Setlocation(data.result.post.location)
    //     Settags(data.result.post.tag)
    //     Setmake_premium(data.result.post.featured)
    // }
    useEffect(() => {
        const elems = document.querySelectorAll("select");
        M.FormSelect.init(elems);
      }, []);
      const success=(message)=>{
        Swal.fire({
          title:message,
          text: "Please wait for 24 hours to approve your item",
          icon: "success"
        });
      }

      var singleFileObj = [];
      var singleFileArray = [];
   const [singleFile, setSingleFile] = useState([]);
   const [imageurl, setimageurl] = useState([]);
   const [profilePic, setProfilePic] = useState(null); //
   const uploadSingleFiles =async (e) => {
     const file = e.target.files[0];
     if (file) {
         const fileUrl = URL.createObjectURL(file); // Create a preview URL
         setSingleFile((prevFiles) => [...prevFiles, fileUrl]); // Add preview to the state
           const formData = new FormData();
           formData.append("image[0]", file);
         try {
           const response = await axios.post(`${BASE_URL}/image_upload`, formData, {
             headers: { "Content-Type": "multipart/form-data" },
           });
           console.log("Image uploaded successfully:", response.data);
           setimageurl((prevFiles) => [...prevFiles, response.data.image_name]);
         } catch (error) {
           console.error("Error uploading image:", error);
         }
       }
   };
   const uploadFiles = (e) => {
     e.preventDefault();
     console.log(singleFile);
   };
   const removeImage = (index) => {
     console.log("reomve");
     setSingleFile([
       ...singleFile.slice(0, index),
       ...singleFile.slice(index + 1, singleFile.length)
     ]);
     setimageurl([...imageurl.slice(0, index),
         ...imageurl.slice(index + 1, imageurl.length)])
   };
   const handleUploadClick = () => {
     document.getElementById("fileInput").click(); // Simulate file input click
   };
 
   const handleImageChange = (e) => {
     const file = e.target.files[0];
     if (file) {
         setSingleFile(file)
       const reader = new FileReader();
       reader.onload = () => {
         setProfilePic(reader.result); 
       };
       reader.readAsDataURL(file); 
     }
   };
   
 const onSubmit = (e) => {
    e.preventDefault();
    // console.log(formData)
    fetch(`${BASE_URL}/updatepost`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            post_id:props.data.result.post.id,
            category: category,
            sub_category: sub_category,
            title: title,
            description:description,
            image:imageurl,
            price:price,
            phone: phone,
            city:city,
            location:location,
            latitude:latitude,
            longitude:longitude,
            tags: tags,
            make_premium:make_premium,
            agree_for_term:agree_for_term,
           negotiable:negotiable
        })
    }).then((res)=>{
        
        return res.text();
    }).then((data)=>{
        success('Item Posted Successfully')
        console.log('jhfjf',data);  
    }).catch((err)=>{
        console.log(err.message);   
    })

}
useEffect(() => {
  fetch(`${BASE_URL}/posts/${'33'}/edit?user_id=${'20'}`, {
  }).then((res)=>{
      return res.json();
  }).then((data)=>{
      console.log('jhfjf',data); 
  }).catch((err)=>{
      console.log(err.message);   
  })
},[]);
  return (
    <>
            <div className='left_a'>

                    <p className='ft_18 roboto_medium d_black mt_20'>Post An Advertise</p>
                    <form >
                    <div class="input-field col s12 ">
                        <select class="icons"  name='category' value={category} onChange={(e) => Setcategory(e.target.value)} >
                            <option value="" disabled selected>Select Category</option>
                            <option value="1" data-icon="images/sample-1.jpg">Mobile</option>
                            <option value="2" data-icon="images/sample-1.jpg">Car</option>
                        </select>
                    </div>
                    {/* <div class="input-field col s12 ">
                        <select class="icons">
                            <option value="" disabled selected>Select Sub Category</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                        </select>
                    </div> */}

                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Title</p>
                        <input placeholder="Enter title for your listing..." id="first_name" type="text" class="validate"   name="title"value={title}onChange={(e) => Settitle(e.target.value)} />
                    </div>

                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Description</p>
                        <textarea class="review_area mt_20 ft_14" placeholder="Include condition, features and reason for selling..." name='description' value={description}onChange={(e) => Setdescription(e.target.value)}></textarea>
                    </div>


                    <p className='ft_14 roboto_medium d_black mt_10'>Upload pictures upto 10</p>
                    <div class="img_uplo mt_10">
                        {singleFile.length !== 0 &&
                            singleFile.map((url, index) => (
                            <>
                            <div class="circle22 p_image" key={url}>
                                <img class="profile-pic" src={url} onClick={handleUploadClick}></img>
                                <span
                                    className="remove_img"
                                    onClick={() => removeImage(index)}>
                                    X
                                </span>
                            </div>
                            </>
                        ))}
                        {singleFile.length > 8 ? null : (
                        <>
                        <div class="p_image"  onClick={handleUploadClick}>
                            <img class="fa fa-camera upload-button" src={Camera} ></img>
                            <input class="file-upload" type="file" accept="image/*" id="fileInput" onChange={uploadSingleFiles} />
                            <p className='ft_14 d_black roboto_medium'>Add Photo</p>
                        </div>
                        </>
                        )}


                    
                    </div>

                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Price</p>
                        <input placeholder="Set a price" id="first_name" type="number" class="validate"  name='price' value={price}onChange={(e) => Setprice(e.target.value)} />
                        
                    </div>
                    <label>
                        <input type="checkbox"/>
                        <span name='negotiable' onClick={() => Setnegotiable(1)}>Negotiable</span>
                    </label>

                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Phone Number</p>
                        <input id="icon_telephone" type="number" class="validate"name='phone'  value={phone}onChange={(e) => Setphone(e.target.value)} />
                    </div>


                    <div class="input-field col s12 ">
                        <p className='ft_14 roboto_medium d_black mt_10'>City</p>
                        <select  name='city' value={city}onChange={(e) => Setcity(e.target.value)} >
                            <option value="" disabled selected>Select City</option>
                            <option value="1234">Delhi</option>
                            <option value="123456">Noida</option>
                            <option value="241406">Hardoi</option>
                        </select>
                    </div>

                    <div class="input-field col s12 ">
                        <p className='ft_14 roboto_medium d_black mt_10'>Address</p>
                        <input id="icon_telephone" type="text" class="validate"name='location'  value={location}onChange={(e) => Setlocation(e.target.value)} />
                    </div>

                    <div class="input-field col s12 ">
                        <p className='ft_14 roboto_medium d_black mt_10'>Tags</p>
                        <input id="icon_telephone" type="text" class="validate" name='tags' value={tags}  onChange={(e) => Settags(e.target.value)}/>
                    </div>

                    
                    {/* <!--premium --> */}
                    <p className='ft_14 roboto_medium d_black mt_10'>Select Plan</p>
                    <div className='d_flex l_gap mt_10'>
                        <div className='listing_charges'>
                            <label>
                                <input name="make_premium" type="radio"value="0"
                               checked={make_premium === "0"} onChange={(e) => Setmake_premium(e.target.value)} />
                                <span>Free Listing</span>
                            </label>
                            <p className='ft_14 roboto_medium d_elblack '>Free Listing Charges: 0.1 Usd</p>
                        </div>
                        <div className='listing_charges'>
                            <label>
                                <input name="make_premium" type="radio"value="1" checked={make_premium === "1"} onChange={(e) => Setmake_premium(e.target.value)} />
                                <span>Premium Listing</span>
                            </label>
                            <p className='ft_14 roboto_medium d_elblack '>Premium Listing Charges: 0.2 Usd</p>
                        </div>
                    </div>
                    <div class="mt_20">
                        <label>
                            <input type="checkbox" />
                            <span name='agree_for_term' onClick={() => Setagree_for_term(1)}>I have read and agree to the Terms & Conditions</span>
                        </label>
                    </div>
                    <button class="mt_20 btn_full pr_btn roboto_regular waves-effect ft_14 mt_10"  onClick={onSubmit}>
                        <img src={Add} alt=" "className='btn_img'/>
                        Post an AD
                    </button>
                  </form>  
                    
            </div>
            <div className='right_a'></div>

      
    </>
  )
}

export default EditProduct
