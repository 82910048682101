import React, { useMemo, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query';

const Categories = (props) => {
  const navigate=useNavigate()
  const redirect = () => {
    navigate(`/Categories/${props.slug}/${props.name}`)
  }
  return (
    <> 
        <div className="cat center" onClick={redirect}>
          <div className="cat_icon">
            <img src={props.pic} alt={props.pic} />
          </div>
          <p className="ft_14 mt_10 roboto_regular ">{props.name}</p>
        </div>
    </>
  )
}

export default Categories
