import React, { useEffect, useState } from 'react'
import Add from '../../images/add.svg'
import Camera from '../../images/camera.svg'
import { BASE_URL } from '../../Apis/config';
import { useParams } from 'react-router-dom';
const EditProfile = () => { 
    const {id}=useParams();
    const [data,setData]=useState([])
  useEffect(() => {
    fetch(`${BASE_URL}/getprofile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user_id : id,
      })
    }).then((res)=>{
        return res.json();
    }).then((data)=>{
        console.log('jhfjf',data); 
        setData(data.result)
    }).catch((err)=>{
        console.log(err.message);   
    })
  },[]);
  return (
    <div  className="body_area grid_main">
            <div className='left_a'>

                    <p className='ft_18 roboto_medium d_black mt_20'>Edit User Details</p>
                    <form >
                    {/* <div class="input-field col s12 ">
                        <select class="icons">
                            <option value="" disabled selected>Select Sub Category</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                        </select>
                    </div> */}


{/* .....Image Upload... */}
                {/* <div class="mt_10 d_center">
                    <div class="circle22 p_image img_user " >
                        <img class="profile-pic" src={Camera} ></img>
                        </div>
                    <div class="change_img" >
                        <img class="fa fa-camera upload-button" src={Camera} ></img>
                        <input class="file-upload" type="file" accept="image/*" id="fileInput" />
                    </div>
                </div> */}
                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Name</p>
                        <input placeholder="Enter your name" id="first_name" type="text" class="validate"   name="name" />
                    </div>

                    <div class="input-field col s12">
                        <p className='ft_14 roboto_medium d_black mt_10'>Phone Number</p>
                        <input id="icon_telephone" type="number" class="validate"name='phone' />
                    </div>

                    <div class="input-field col s12 ">
                        <p className='ft_14 roboto_medium d_black mt_10'>Email</p>
                        <input id="icon_telephone" type="email" class="validate"name='email'  />
                    </div>
                    <button class="mt_20 btn_full pr_btn roboto_regular waves-effect ft_14 mt_10" >
                        
                       Save Changes
                    </button>
                  </form>  
                    
            </div>
            <div className='right_a'></div>

        </div>       
  )
}

export default EditProfile
