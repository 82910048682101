import React from 'react'

const Membership = () => {
  return (
    <>
    
    <div className="col-lg-9 col-md-12">
  <div className="dashboard-box margin-top-0">
    {/* Headline */}
    <div className="headline">
      <h3>
        <i className="icon-feather-gift" /> Current Plan
      </h3>
    </div>
    <div className="content with-padding">
      <div className="table-responsive">
        <table id="js-table-list" className="basic-table dashboard-box-list">
          <tbody>
            <tr>
              <th>Membership</th>
              <th>Start Date</th>
              <th>expiry date</th>
            </tr>
            <tr>
              <td>Free Plan</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td colSpan={7}>
                <a
                  href="https://buynsell.mchamp.xyz/pricing"
                  className="button"
                >
                  Change Plan
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  {/* <center>
    <div className="google-ads-728x90 margin-top-30 margin-bottom-30 my-4">
      <img
        src="https://via.placeholder.com/720x90"
        width="100%"
        height="100%"
      />
    </div>
  </center> */}
</div>
    </>
  )
}

export default Membership
