import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Test from '../Test';
import logo from '../../images/logo.png'
import nav  from '../../images/hamburger.svg'
import location  from '../../images/location.svg'
import arrow_black from '../../images/arrow_black.svg'
import search from '../../images/search.svg'
import M from "materialize-css/dist/js/materialize.min.js";
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { BASE_URL } from '../../Apis/config';
const Country = async () => {
  const { data } = await axios.get(`${BASE_URL}/allcountry`);
  return data.result;
};
const countries = [
  "United States",
  "India",
  "Canada",
  "Australia",
  "United Kingdom",
  "Germany",
  "France",
  "China",
  "Japan",
  "Brazil",
  "South Africa",
]; // Example list of countries
const Header = () => {
  // const queryClient = useQueryClient();
  // const { data, error, isLoading } = useQuery({
  //   queryKey: ["Country"],
  //   queryFn: Country
  // });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const showtoggle = () => {
    settoggle(!toggle);
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCountries = countries.filter((country) =>
    country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectCountry = (country) => {
    setSelectedCountry(country);
    setIsPopupOpen(false);
  };
  useEffect(() => {
    const sidenav = document.querySelectorAll(".sidenav");
    M.Sidenav.init(sidenav, {});
  }, []);
  return (
    <>
<div className="header navbar-fixed">
  <nav className="top_nav">
    <div className="h_left">
      <a onClick={showtoggle}
      data-target="mobile-nav"
        className="sidenav-trigger a_logo "
      >
        <img src={nav} alt="menu" />
      </a>
      <Link to={'/'} className="a_logo">
        <img src={logo} alt="logo" className="logo" />
      </Link>
    </div>
    <div className="h_right">
      <a onClick={togglePopup} className=" location_btn roboto_regular ft_14">
        <img src={location} alt={location} />
        {(!selectedCountry)?'Location':selectedCountry}
        <img src={arrow_black} alt={location} />
        
      </a>
    </div>
    {isPopupOpen && (
        <div className="popup">
          <div className="popup-header">
            <div class="search mt_10">
              <img src={search} alt="search" />
              <input type="search" placeholder="Search country..." value={searchTerm}
              onChange={handleSearchChange}
              className="search-input" />
            </div>
            
             <button onClick={togglePopup} className='cross'>
                ✖
              </button>
          </div>
          <ul className="country-list">
            {filteredCountries.map((country, index) => (
              <li
                key={index}
                className="country-item"
                onClick={() => selectCountry(country)}
              >
                {country}
              </li>
            ))}
          </ul>
        </div>
      )}
  </nav>
  <ul  id="mobile-nav" className="sidenav">
          <li><Link to={'/dashboard'}>Dashboard</Link></li>  
          <li><Link to={'/AddProduct'}>Post AD</Link></li>
          <li><Link to={'/MyListing'}>My Listing</Link></li>
          <li><Link to={'/Favourite'}>Favourite</Link></li>
          <li><div class="divider"></div></li>
          <li><a class="waves-effect" href="#!">FAQ</a></li>
          <li><a class="waves-effect" href="#!">Privacy Policy</a></li>
          <li><a class="waves-effect" href="#!">Terms & Conditions</a></li>
        </ul>
</div>

    </>
  )
}

export default Header
