import React from 'react'
import { BASE_URL } from '../Apis/config';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom/client'
import {
  http,
  type Address,
  type Hash,
  type TransactionReceipt,
  createPublicClient,
  createWalletClient,
  custom,
  parseEther,
  parseUnits,
  encodeFunctionData,
  stringify,
  getContract,
  formatEther,
} from 'viem'
import 'viem/window'
import loader from '../images/loader.gif'
const { celo } = require("viem/chains");
const { stableTokenABI } = require("@celo/abis");

const PostPayment = () => {
  //payment code//
	 const [address, setAddress] = useState('');
  const [waccount, setWaccount] = useState('');
  const [hash, setHash] = useState();
  const [receipt, setReceipt] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [ClickValue, SetClickvalue] = useState(false);
  const [load, SetLoad] = useState(false);
  let { flag } = useParams();
  const {id,uid,ptype}=useParams()
  const nav=useNavigate()

  const generateRandomId = async() => {
    if (window && window.ethereum) {
    
      if (window.ethereum.isMiniPay) {
        // User is using Minipay
    
        // Requesting account addresses
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });
        // Injected wallets inject all available addresses,
        // to comply with API Minipay injects one address but in the form of array
        //console.log(accounts[0]);
         return accounts[0];
      }
      }
      
  };

  const success=(message)=>{
    Swal.fire({
      title:message,
      // text: "Please wait for 24 hours to approve your item",
      icon: "success"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        nav(`/ProductDetails/${id}`)
      };
    });
  }	

   const coindeduct=()=>{
    fetch(`${BASE_URL}/savecoinlog`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id :uid,
        post_id :id,
       type : "2",
       ip : "322.32.32.323",
       amount_type: "2",
       message : "1"
    })
  }).then((res)=>{
      return res.json();
  }).then((data)=>{
    if(data.message=='Deduct Coin'){
      success('Payment Successfully')
    }
   console.log('dddffe',data)
  }).catch((err)=>{
      console.log(err.message);
  })
  }

   const FailHanler=(message)=>{
    Swal.fire({
    icon: "error",
   title: message,
   text: "Something went wrong!",
   allowOutsideClick: false,
   //showConfirmButton:false
  // footer: '<Navlink to={'/'}>Why do I have this issue?</Navlink>'
    });
  }

  const STABLE_TOKEN_ADDRESS = "0x765DE816845861e75A25fCA122bb6898B8B1282a";
    const CheckBalance=async(publicClient, address)=>{
      let StableTokenContract = getContract({
        abi: stableTokenABI,
        address: STABLE_TOKEN_ADDRESS,
        publicClient,
    });
  

    let balanceInBigNumber = await StableTokenContract.read.balanceOf([
        address,
    ]);
  
    let balanceInWei = balanceInBigNumber.toString();
  
    let balanceInEthers = formatEther(balanceInWei);
  
    return balanceInEthers;
      
    }
    const publicClient = createPublicClient({
      chain: celo,
      transport: http(),
    }); // Mainnet
    const walletClient = createWalletClient({
  	chain: celo,
  	transport: custom(window.ethereum),
   })
    const GetAddress = async() => {
      if (window && window.ethereum) {
      
        if (window.ethereum.isMiniPay) {
          // User is using Minipay
      
          // Requesting account addresses
          let accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            params: [],
          });
          // Injected wallets inject all available addresses,
          // to comply with API Minipay injects one address but in the form of array
          //console.log(accounts[0]);
           return accounts[0];
        }
        }
    };

    //const connect = async () => {
     //const [address] = await walletClient.requestAddresses()
     //setAccount(address)
   // }

    //funciton start//
    const AmountDeduct = async (address,totbalance,amount) => {

    try {	    
	    var resp = "failed";
    	const [waddress] = await walletClient.requestAddresses()
    	setWaccount(waddress);
	 
    	if (!waddress) return "failed"
    	const hash = await walletClient.sendTransaction({
		account: waddress,
    		to: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
    		data: encodeFunctionData({
      		abi: stableTokenABI, // Token ABI can be fetched from Explorer.
      		functionName: "transfer",
      	args: [
        	'0x0D014767A117ECa45B434b1D646558E5e65e8513',
        	// Different tokens can have different decimals, cUSD (18), USDC (6)
        	parseUnits(`${Number(amount)}`, 18),
      	],
    	}),
    	// If the wallet is connected to a different network then you get an error.
    	//ichain: celoAlfajores,
    	chain: celo,
    	})
    	setHash(hash)
 

      	if (hash) {
        	const receipt = await publicClient.waitForTransactionReceipt({ hash })
        	setReceipt(receipt)

		if (receipt.status === "success") {
			//alert("Transaction Success");
			resp = "success";
  	  	// Do something after transaction is successful.
  		} else {
			//alert("500");
    		// Do something after transaction has failed.
  		}

      	}

      } catch (error) {
        nav(`/ProductDetails/${id}`)
      }    
	return resp;

    }	

  //payment code end//	

  const Pay  = (balance) => {
	  SetLoad(true);
	  SetClickvalue(true);
          const myFirstPromise = new Promise((resolve, reject) => {
            resolve( GetAddress())
          });
          myFirstPromise.then((successMessage) => {
            setAddress(successMessage);
            const resPromis = new Promise((resolve, reject) => {
              resolve( CheckBalance(publicClient,successMessage))
            });
            resPromis.then((totbalance) => {
              if(totbalance>=(balance)){
                const deduct = new Promise((resolve, reject) => {
                  resolve(AmountDeduct(address,totbalance,balance))
                });
                deduct.then((message) => {
                  //alert(message)
                  if(message==="success"){
			 // alert(user_id);
                         coindeduct()   
                  }
                  else{
                    //alert('transaction Failed')
		    FailHanler("Transaction Failed")
                    if(flag==1){
                	nav(`/`)
            	    } else {
                    	//window.location.reload();
                	nav(`/`)
         	    }
		    SetClickvalue(false)
                    SetLoad(false)
                  }
                });
              }
              else{
                //alert('You  dont have  balance  ');
		FailHanler("You don't have balance")
                if(flag==1){
         	       nav(`/`)
            	} else {
                	  //  window.location.reload();
                	nav(`/`)
         	}
		SetClickvalue(false)
		SetLoad(false)
              }
            });
          });
    };	
  

  const BalanceAlert = () => {
    Swal.fire({
      title: `Pay CUSD ${ptype==0?'0.1':'0.2'} for this post`,
      showDenyButton: true,
      confirmButtonText: "Pay Now",
      denyButtonText: `Not Now`,
      allowOutsideClick: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
       SetLoad(true)	      
       if(ptype==1) {
	      Pay('0.2')
       } else if(ptype==0) {
	       Pay('0.1')
       } 
      }
      else if (result.isDenied) {
        nav(`/`)
      }
    });
  }
  useEffect(() => {
    BalanceAlert()
  },[]);
  return (
    <>
      { load &&<div className='center_vh'><img src={loader}/></div>}
    </>
  )
}

export default PostPayment
