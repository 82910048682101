import React from 'react'

const ProfilePage = () => {
    return (
        <div className="col-lg-9 col-md-12">
            <div className="dashboard-box margin-top-0">
                <div className="content with-padding">
                    <div className="row dashboard-profile">
                        <div className="col-xl-6 col-md-6 col-sm-12">
                            <div className="user-img">
                                <img
                                    src="https://buynsell.mchamp.xyz/storage/profile/default_user.png"
                                    alt="user"
                                    className="img-responsive"
                                />
                            </div>
                            <div>
                                <h2>ritik</h2>
                                <span>
                                    <i className="bi bi-gift" style={{margin:'0px 5px 0px 0px'}} />
                                    Membership : Free Plan
                                </span>
                                <br />
                                <small>Username : ritik2024</small>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12 text-right">
                            <span className="dashboard-badge">
                                <strong>0</strong>
                                <i className="bi bi-heart" /> Favorite Listings
                            </span>
                            <span className="dashboard-badge">
                                <strong>0</strong>
                                <i className="bi bi-briefcase" /> My Listings
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Dashboard Box */}
            <div className="dashboard-box main-box-in-row">
                <div className="headline">
                    <h3>
                        <i className="bi bi-bar-chart" /> This Month views
                    </h3>
                </div>
                <div className="content">
                    {/* Chart */}
                    <div className="chart">
                        <canvas id="chart" width={100} height={45} />
                    </div>
                </div>
            </div>
            {/* Dashboard Box / End */}
            {/* <center>
                <div className="google-ads-728x90 margin-top-30 margin-bottom-30 my-4">
                    <img
                        src="https://via.placeholder.com/720x90"
                        width="100%"
                        height="100%"
                    />
                </div>
            </center> */}
        </div>
    )
}

export default ProfilePage
