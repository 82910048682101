import React, { memo, useEffect, useState } from 'react'
import Categories from '../Categories'
import Ads from '../Ads/Index'
import arrow from '../../images/arrow.svg'
import add from '../../images/add.svg'

import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../Apis/config'
import axios from 'axios';
import Premiumad from '../Ads/Premiumad'
import { Link, Links, Navigate, useNavigate } from 'react-router-dom'
import search from '../../images/search.svg'
import Search from '../Header/Search';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const CategoriesApi = async () => {
  const { data } = await axios.get(BASE_URL);
  return data;
};
const HeroSec = (props) => {
  const id=props.id
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["Data"],
    queryFn: CategoriesApi
  });
  const nav=useNavigate()
const res=()=>{
  nav('/AddProduct')
}
  queryClient.setQueryData(['filteredData'], data);
  if (isLoading) {
    return <div>Loading...</div>;}
    if(data){
      cookies.set('Categories',data.result.categories, { path: '/' });
      queryClient.setQueryData(['Related'], data.result.latestPosts);
      // queryClient.setQueryData(['Categories'],data.result.categories);
    }
  return (
    <>
     <div className="body_area">
      <Search></Search>
<div className="category_s mt_20">
{data&& data.result.categories.map((ele, i) => {
                  return (
 <Categories pic={ele.picture} count={ele.posts_count} name= {ele.name} slug={ele.slug}></Categories>
)
})
} 
</div>
<div class="btm_fixed cont_user mt_10 d_flex d_none btm_home" >
  <button class=" btn_full pr_btn roboto_regular waves-effect ft_14 " onClick={res}><img src={add} alt=" " class="btn_img"/>Post an AD</button>
</div>

<div className="d_flex mt_20">
  <p className="ft_18 roboto_medium">Fresh recommendations</p>
  <Link  to={'/Categories'} className="ft_14 location_btn waves-effect roboto_regular d_blue">
    View all
    <img src={arrow} alt="arrow" />
  </Link>
</div>
<div className="cards mt_10">
{data&& data.result.premiumPosts.map((ele, i) => {             
 return (
  <Premiumad pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured}id={ele.id} uid={id}></Premiumad>
)
})
} 
</div>
<div className="d_flex mt_20">
  <p className="ft_18 roboto_medium">Latest Listings</p>
  <Link to={'/Categories'}  className="ft_14 location_btn waves-effect roboto_regular d_blue">
    View all
    <img src={arrow} alt="arrow" />
  </Link>
</div>
<div className="list_items mt_10">
{data&& data.result.latestPosts.map((ele, i) => {             
 return (
  <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id}uid={id}></Ads>
)
})
} 

</div>



     </div>

    </>
  )

}

export default memo(HeroSec);
