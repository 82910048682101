import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query';
import car from '../../images/car.jpg'
import share from '../../images/share.svg'
import inactive from '../../images/like_inactive.svg'
import locatin from '../../images/location_light.svg'
import like from '../../images/liked.svg'
import { handleShare } from '../../Pages/Share'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Apis/config';
import { imageUrl } from '../../Apis/config'
const Premiumad = (props) => {
  const navigate = useNavigate();
  const [Liked, setLiked] = useState(false);
  const queryClient = useQueryClient();
  const  uid= queryClient.getQueryData(['Id']); 
  const newid=!(uid)?props.uid:uid
  // const  uid= queryClient.getQueryData(['Id']);  
  const isLiked = (id) => {
    setLiked(!Liked);
    fetch(`${BASE_URL}/savewishlist`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          user_id :newid,
          post_id : id
    })
  }).then((res)=>{
      return res.text();
  }).then((data)=>{
      console.log('jhfjf',data);  
  }).catch((err)=>{
      console.log(err.message);   
  })
  };
  const redirect = () => {
    navigate(`/ProductDetails/${props.id}`)
  }
const imgarray = props.pic.split(",");
  const imgurl=`${imageUrl}/${imgarray[0]}`
  const date = new Date(props.date);
const day = date.getDate();
const month = date.toLocaleString('default', { month: 'short' });
const year = date.getFullYear();
const formattedDate = `${day} ${month} ${year}`;
  return (
    <>
            {props.featured ==1&&  <div className="card_n " >
                  <img src={imgurl} alt={props.pic}onClick={redirect}/>
                  <div className="featured">
                    <p className="ft_14 roboto_medium">Featured</p>
                  </div>
                  <div className="feature_c_details c_details mt_20">
                    {/* like share option*/}
                    <div className="like_share like_share2 ">
                      <a onClick={handleShare}>
                        <img src={share} alt="share" />
                      </a>
                      <a onClick={() => isLiked(props.id)}>
                        <img src={Liked ? like : inactive} alt="Like" />
                      </a>
                    </div>
                    {/* like share option*/}
                    <div onClick={redirect} className='randomclass '>
                      <p className="ft_18 roboto_medium d_blue">$ {props.price}</p>
                      <p className="ft_14 roboto_regular d_lblack">{formattedDate}</p>
                      <p className="ft_14 roboto_regular d_elblack line_2">
                        {props.title}
                      </p>
                      <p className="ft_14 roboto_regular d_elblack line_1">
                        <img src={locatin} alt="location" />
                        {props.location}
                      </p>
                    </div>
                   
                  </div>
                </div>}
              {props.featured ==0&&<div className="card_n">
              <img  onClick={redirect} src={imgurl}  alt={props.pic}/>
                
                <div class="c_details mt_10">
                     <div class="like_share like_share2 newclass">
                     <a onClick={handleShare}>
                      <img src={share} alt="share" />
                    </a>
                    <a onClick={() => isLiked(props.id)}>
                      <img src={Liked ? like : inactive} alt="Like" />
                    </a>
                    </div>
                    <div onClick={redirect} className='randomclass'>
                    <p className="ft_18 roboto_medium d_blue">$ {props.price}</p>
                  <p className="ft_14 roboto_regular d_lblack">{formattedDate}</p>
                  <p className="ft_14 roboto_regular d_elblack line_2">
                    {props.title}
                  </p>
                  <p className="ft_14 roboto_regular d_elblack line_1">
                    <img src={locatin} alt="location" />
                    {props.location}
                  </p>
                    </div>
                   
                </div>
              </div>}
    </>
  )
}

export default Premiumad
