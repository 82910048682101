// import './Css/Bootstrap.css'
// import './Css/color.css'
// import './Css/flag.min.css'
// import './Css/fontawesome.css'
// import './Css/icons.css'
// import './Css/slick.css'
// import './Css/snacbar.min.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Dashboard from './components/Dashboard';
import Layout from './Layout';
import DashboardAdsContainer from './components/Dashboard/DashboardAdsContainer';
import ProfilePage from './components/Dashboard/ProfilePage';
import Transection from './components/Dashboard/Transection';
import Account from './components/Dashboard/AccountSetting';
import Membership from './components/Dashboard/Member/Membership';
import HeroSec from './components/HeroSec';
import Test from './components/Test';
import ReadBlog from './components/Blog/ReadBlog';
import CatDetails from './components/Categories/CatDetails';
import Payment from './Pages/Payment';
import { useEffect, useState } from 'react';
import Profile from './components/User/Profile';
import AddProduct from './components/Dashboard/Product/AddProduct';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { BASE_URL } from './Apis/config';
import ProductDetails from './components/Dashboard/Product/ProductDetails';
import EditPage from './Pages/EditPage';
import FavouriteProduct from './components/Dashboard/Product/FavouriteProduct';
import EditProfile from './components/User/EditProfile';
import PostPayment from './Pages/PostPayment';
const cookies = new Cookies();
const generateRandomId = async() => {
  if (window && window.ethereum) {
    if (window.ethereum.isMiniPay) {
      // User is using Minipay
      // Requesting account addresses
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [],
      });
      // Injected wallets inject all available addresses,
      // to comply with API Minipay injects one address but in the form of array
      //console.log(accounts[0]);
       return accounts[0];
    }
    }
    // return 'hdfkfhggtuhgturuurtut'
};
function App() {
  const [isLoading,SetisLoading]=useState(false)
  const [id,Setid]=useState('')
  const queryClient = useQueryClient();
  // const queryParams = {
  //     name : "Monu Yadav",
  //     username : address,
  //     email : address,
  //     password: "123456781",
  //     agree_for_term: "yes"
  //   };
  //   const { data, isLoading, isError, error } = useQuery({
  //     queryKey: ["Registration", queryParams],
  //     queryFn: registerUser, 
  //     refetchOnWindowFocus: false,
  //   });
    const regi=(value)=>{
      fetch(`${BASE_URL}/customsignup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name : "User",
          username : value,
          email :'',
          password: "123456781",
          agree_for_term: "yes"
      })
    }).then((res)=>{
        return res.json();
    }).then((data)=>{
      cookies.set('Id', data.result[0].id, { path: '/' });
    //  queryClient.setQueryData(['Id'], data.result[0].id);
     Setid(data.result[0].id)
    }).catch((err)=>{
        console.log(err.message);   
    })
    }
    useEffect(() => {
      ; (async () => {
        const address = await generateRandomId()
        if(address){
          regi(address)
          SetisLoading(true)
        }
        else if(!address){
          regi('0x7784d7Cc9F0Dc82EE1cCA9aD9d1f17f1a81c5d712')
          SetisLoading(true)
        }
       
      })()
    }, []); 
    // console.log(data.result[0].id)
    // queryClient.setQueryData(['Id'], data.result[0].id);
    if (!isLoading) {
      return <div>Loading...</div>;}

      // if(data){
      //   queryClient.setQueryData(['Id'], data.result[0].id);
      //   console.log(data.result[0].id)
      // }
  return (  
    <>
     <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path='/test' element={<Test />} />
          <Route path="/" element={<Layout/>}>
          <Route index element={<HeroSec id={id}  />} />
          <Route path="/Categories/:slug" element={<CatDetails id={id} />}/>
          <Route path="/Categories/" element={<CatDetails id={id} />}/>
          <Route path="/Categories/:slug/:name" element={<CatDetails id={id} />}/>
          <Route path="/ProductDetails/:id" element={<ProductDetails />}/>
          <Route path="/Profile/" element={<Profile   />}/>
          <Route path="dashboard" element={<Dashboard   />}/>
          <Route path="MyListing" element={<Dashboard   />}/>
          <Route path="AddProduct" element={<AddProduct  id={id}  />}/>
          <Route path="Edit/:pid/:uid" element={<EditPage   />}/>
          <Route path="Favourite" element={<FavouriteProduct/>}/>
          <Route path="Payment/:id/:uid" element={<Payment/>}/>
          <Route path="PostPayment/:id/:uid/:ptype" element={<PostPayment/>}/>
          <Route path="EditProfile/:id" element={<EditProfile/>}/>
          </Route>
        </Routes>
      </Router>
      </QueryClientProvider>
    </>
  );
}
export default App;