import React from 'react'

const Transection = () => {
  return (
   <>
   <div className="col-lg-9 col-md-12">
  <table className="basic-table">
    <thead>
      <tr>
        <th>ID</th>
        <th>Title</th>
        <th>Price</th>
        <th>Payment Method</th>
        <th>Date</th>
        <th>Status</th>
        <th>Invoice</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan={7}>No Data Found.</td>
      </tr>
    </tbody>
  </table>
  {/* <center>
    <div className="google-ads-728x90 margin-top-30 margin-bottom-30 my-4">
      <img
        src="https://via.placeholder.com/720x90"
        width="100%"
        height="100%"
      />
    </div>
  </center> */}
</div>
   </>
  )
}

export default Transection
