import React, { useState } from 'react'
import Ads from '../Ads/Index'
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../Apis/config'
import axios from 'axios';
import Search from '../Header/Search';
import share from '../../images/share.svg'
import inactive from '../../images/like_inactive.svg'
import location from '../../images/location_light.svg'
import { useLocation, useParams } from 'react-router-dom';
import {handleShare} from '../../Pages/Share'
import like from '../../images/liked.svg'
import Premiumad from '../Ads/Premiumad';
const CatDetails = (props) => {
  const id=props.id
  const{slug,name}=useParams()
  const [Liked,setLiked] = useState(false);
  const [status,setstatus]=useState('')
  const isLiked = () => {
    setLiked(!Liked);
  };
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const endPath = locationArray[locationArray.length - 1];
  console.log(endPath)
  const CategoriDetails = async () => {
    const { data } = await axios.get(`${BASE_URL}/category/${!(slug)?endPath:slug}`);
    return data;
  };
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["CateDetails"],
    queryFn: CategoriDetails
  });
  // queryClient.setQueryData(['CateDetails'], data);
  const res = queryClient.getQueryData(['Related']);  
  return (
    <>
      <div className="body_area">
      {isLoading&&<div>Loading...</div>}
        {/* top search bar*/}
        {/* top search bar*/}
        <Search></Search>
{data &&  <p class="ft_18 mt_10 roboto_medium">Buy & Sell {name}</p>
}        {!data&&  <p className="ft_14 mt_10 roboto_regular d_lblack">
         No result Found
        </p>}
       
       { !data&&<p className="ft_18 mt_10 roboto_medium">
          Recommended listing
        </p>}
        
        {/* list*/}
        <div className="list_items mt_10">

        {!data&& res&&res.map((ele, i) => {             
 return (

<Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id} uid={id}></Ads>
 
)
})
}  </div>
        <div className="list_items mt_20">
          {/* featured list*/}
          {data&& data.result.posts.data.map((ele, i) => {             
 return (
  <Ads pic={ele.images} title={ele.title} slug={ele.sub_category.slug}
  location={ele.location} price={ele.price} date={ele.expire_date} featured={ele.featured} id={ele.id} uid={id}></Ads>
)
})
} 
        
        </div>
      </div>
    </>
  )
}

export default CatDetails
