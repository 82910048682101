import React from 'react'

const ReadBlog = () => {
  return (
   <>
   <div id="titlebar">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h2>First Blog</h2>
        {/* Breadcrumbs */}
        <nav id="breadcrumbs">
          <ul>
            <li>
              <a href="http://localhost/Olx/">Home</a>
            </li>
            <li>
              <a href="http://localhost/Olx/blog">Blog</a>
            </li>
            <li>First Blog</li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
<div className="container margin-bottom-50">
  <div className="row">
    <div className="col-md-8 col-12">
      <div className="listings-container blog-listing blog-single">
        <div className="job-listing">
          <div className="job-listing-details">
            <div className="job-listing-footer">
              <ul>
                <li>
                <i className="bi bi-person-fill" /> <a href="http://localhost/Olx/blog/author/admin">
                    by Admin
                  </a>
                </li>
                <li>
                  <i className="bi bi-clock" /> 15, Jan 2020
                </li>
                <li>
                  <div className="blog-cat">
                    <i className="bi bi-folder" />{" "}
                    <a href="http://localhost/Olx/blog/category/fashion">
                      Fashion
                    </a>
                    ,{" "}
                    <a href="http://localhost/Olx/blog/category/lifestyle">
                      Lifestyle
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="job-listing-description">
              <h2 className="job-listing-title">First Blog</h2>
              <div className="user-html">
                <p>
                  Consectetur adipisicing elitsed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua Ut enim ad minim veniam, quis
                  nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commo do consequat. Duis aute irure dolor in reprehenderit in
                  voluptate velit esse cillum dolore eu fugiat nulla paria tur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <blockquote>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla paria tur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </blockquote>
                <p>
                  Elitsed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commo do consequat. Duis
                  aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla paria tur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim.
                </p>
                <p />
                <p />
              </div>
              <div className="job-tags margin-bottom-20">
                Tags:{" "}
                <a href="http://localhost/Olx/blog?s=travel-fun">travel fun</a>{" "}
                <a href="http://localhost/Olx/blog?s=love"> love</a>
              </div>
              <ul className="share-buttons-icons margin-bottom-10">
                <li>
                  <a
                    href="mailto:?subject=First Blog&body=http://localhost/Olx/blog/1/first-blog"
                    data-button-color="#dd4b39"
                    title="Share on Email"
                    data-tippy-placement="top"
                    rel="nofollow"
                    target="_blank"
                  >
                    <i className="bi bi-envelope" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://facebook.com/sharer/sharer.php?u=http://localhost/Olx/blog/1/first-blog"
                    data-button-color="#3b5998"
                    title="Share on Facebook"
                    data-tippy-placement="top"
                    rel="nofollow"
                    target="_blank"
                  >
                    <i className="bi bi-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/share?url=http://localhost/Olx/blog/1/first-blog&text=First Blog"
                    data-button-color="#1da1f2"
                    title="Share on Twitter"
                    data-tippy-placement="top"
                    rel="nofollow"
                    target="_blank"
                  >
                    <i className="bi bi-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/shareArticle?mini=true&url=http://localhost/Olx/blog/1/first-blog"
                    data-button-color="#0077b5"
                    title="Share on LinkedIn"
                    data-tippy-placement="top"
                    rel="nofollow"
                    target="_blank"
                  >
                    <i className="bi bi-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://pinterest.com/pin/create/bookmarklet/?&url=http://localhost/Olx/blog/1/first-blog&description=First Blog"
                    data-button-color="#bd081c"
                    title="Share on Pinterest"
                    data-tippy-placement="top"
                    rel="nofollow"
                    target="_blank"
                  >
                    <i className="bi bi-pinterest" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://web.whatsapp.com/send?text=http://localhost/Olx/blog/1/first-blog"
                    data-button-color="#25d366"
                    title="Share on WhatsApp"
                    data-tippy-placement="top"
                    rel="nofollow"
                    target="_blank"
                  >
                    <i className="bi bi-whatsapp" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-widget" id="respond">
        <h3 className="widget-title">
          Post a Comment
          <small>
            <a
              rel="nofollow"
              id="cancel-comment-reply-link"
              href="javascript:void(0)"
              style={{ display: "none" }}
            >
              Cancel reply
            </a>
          </small>
        </h3>
        <div className="">
          <form
            action="#respond"
            method="post"
            id="commentform"
            className="blog-comment-form"
          >
            <div className="row">
              <div className="col-md-12">
                <p>
                  You are commenting as: <strong>avd12</strong>
                </p>
              </div>
              <div className="col-md-12">
                <textarea
                  rows={5}
                  id="comment-field"
                  className="with-border"
                  name="comment"
                  placeholder="Your comment..."
                  required=""
                  defaultValue={""}
                />
                <button
                  type="submit"
                  name="comment-submit"
                  className="button ripple-effect"
                >
                  Submit
                </button>
                <input
                  type="hidden"
                  name="comment_parent"
                  id="comment_parent"
                  defaultValue={0}
                />
                <input
                  type="hidden"
                  name="comment_post_ID"
                  defaultValue={1}
                  id="comment_post_ID"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-4 hide-under-768px">
      <div className="blog-widget">
        <form action="http://localhost/Olx/blog">
          <div className="input-with-icon">
            <input
              className="with-border"
              type="text"
              placeholder="Search..."
              name="s"
              id="search-widget"
            />
            <i className="bi bi-search" />
          </div>
        </form>
      </div>
      <div className="blog-widget">
        <h3 className="widget-title">Categories</h3>
        <div className="">
          <ul>
            <li className="clearfix">
              <a href="http://localhost/Olx/blog/category/fashion">
                <span className="pull-left">Fashion</span>
                <span className="pull-right">(1)</span>
              </a>
            </li>
            <li className="clearfix">
              <a href="http://localhost/Olx/blog/category/lifestyle">
                <span className="pull-left">Lifestyle</span>
                <span className="pull-right">(1)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="blog-widget">
        <h3 className="widget-title">Recent Blog</h3>
        <div className="recent-post-widget">
          <div>
            <a href="http://localhost/Olx/blog/1/first-blog">
              <img
                className="lazy-load post-thumb"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC"
                data-original="http://localhost/Olx/storage/blog/default.png"
                alt="First Blog"
              />
            </a>
            <div className="recent-post-widget-content">
              <h2>
                <a href="http://localhost/Olx/blog/1/first-blog">First Blog</a>
              </h2>
              <div className="post-date">
                <i className="icon-feather-clock" /> 4 years ago
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-widget">
        <h3 className="widget-title">Testimonials</h3>
        <div className="single-carousel">
          <div className="single-testimonial">
            <div className="single-inner">
              <div className="testimonial-content">
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commo do consequat. Elitsed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua
                </p>
              </div>
              <div className="testi-author-info">
                <div className="image">
                  <img
                    className="lazy-load"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC"
                    data-original="http://localhost/Olx/storage/testimonials/default_user.png"
                    alt="Natasha"
                  />
                </div>
                <h5 className="name">Natasha</h5>
                <span className="designation">Designer</span>
              </div>
            </div>
          </div>
          <div className="single-testimonial">
            <div className="single-inner">
              <div className="testimonial-content">
                <p>
                  Elitsed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip e
                </p>
              </div>
              <div className="testi-author-info">
                <div className="image">
                  <img
                    className="lazy-load"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC"
                    data-original="http://localhost/Olx/storage/testimonials/default_user.png"
                    alt="Steve Roger"
                  />
                </div>
                <h5 className="name">Steve Roger</h5>
                <span className="designation">Content Writer</span>
              </div>
            </div>
          </div>
          <div className="single-testimonial">
            <div className="single-inner">
              <div className="testimonial-content">
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla paria tur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
              </div>
              <div className="testi-author-info">
                <div className="image">
                  <img
                    className="lazy-load"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC"
                    data-original="http://localhost/Olx/storage/testimonials/default_user.png"
                    alt="Tony Stark"
                  />
                </div>
                <h5 className="name">Tony Stark</h5>
                <span className="designation">Social Marketing</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-widget">
        <h3 className="widget-title">Tags</h3>
        <div className="">
          <div className="job-tags">
            <a href="http://localhost/Olx/blog?s=travel-fun">travel fun</a>{" "}
            <a href="http://localhost/Olx/blog?s=love"> love</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
   </>
  )
}

export default ReadBlog
