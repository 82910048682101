import React from 'react'
import DashboardLayout from './DashboardLayout'
import ProfilePage from './ProfilePage'
import DashboardAdsContainer from './DashboardAdsContainer'
import { Outlet } from 'react-router-dom'

const Dashboard = (props) => {
  return (
    <DashboardLayout id={props.id}>
    </DashboardLayout>
  )
}

export default Dashboard
