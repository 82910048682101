import React from 'react'
import search from '../../images/search.svg'
import {useNavigate } from 'react-router-dom'
import  { useState } from "react";
const Search = () => {
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate();
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue.trim()) {
          navigate(`/Categories/${encodeURIComponent(inputValue)}`);
        }
      };
  return (
    <div className="search mt_10">
    <img src={search} alt="search" />
    <input  value={inputValue} type="search" placeholder="Find Cars, Mobile Phones and more..."   onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown} />
  </div>
  )
}

export default Search
