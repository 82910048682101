import React from 'react'
import Empty2 from '../../src/images/Empty.svg';
import Add from '../../src/images/add.svg';
import { useNavigate } from 'react-router-dom';
const Empty = () => {
  const navigate=useNavigate()
  const redirect = () => {
    navigate(`/`)
  }
  return (
   <>
    <div className='center empt_div mt_20' onClick={redirect}>
        <img src={Empty2} alt={Empty2} />
        <p className='ft_14 d_lblack roboto_regular'>No Data available</p>
        <button class="pr_btn roboto_regular  ft_14 mt_20 w_50"><img src={Add} alt=" " class="btn_img"/>Create AD</button>
    </div>
   </>
  )
}

export default Empty
