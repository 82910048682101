import React, { useEffect } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const Layout = ({ children }) => {
    return (
        <>
      
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}
export default Layout
