import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import share from '../../../images/share.svg'
import inactive from '../../../images/like_inactive.svg'
import location from '../../../images/location_light.svg'
import Edit from '../../../images/edit.svg'
import Delete from '../../../images/Delete.svg'
import timer from '../../../images/timer.svg'
import { BASE_URL } from '../../../Apis/config';
import { imageUrl } from '../../../Apis/config'

// const Listing = async () => {
//     const { data } = await axios.get('/posts/my-listing/?user_id=20');
//     console.log('eefer', data.message)
//     return data;
// };
const DashboardAdsContainer = (props) => {
    const nav =useNavigate()
    const res=(pid,uid)=>{
        nav(`/edit/${pid}/${uid}`)
    }
    const DeleteProd=(pid,uid)=>{
        fetch(`${BASE_URL}/posts/deleteMyListing/${pid}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id :uid
        })
      }).then((res)=>{
          return res.text();
      }).then((data)=>{
      }).catch((err)=>{
          console.log(err.message);   
      })
      }
    // const { slug } = useParams();
    // const queryClient = useQueryClient();
    // const { data, error, isLoading } = useQuery({
    //     queryKey: ["MyList"],
    //     queryFn: Listing
    // });
    const imgarray = props.pic.split(",");
  const imgurl=`${imageUrl}/${imgarray[0]}`
    return (
        <div className="card_list  mt_10">
            <div className="card_n">
                <img
                src={imgurl}
                alt="car"
                />
                <div className="c_details">
                <div className="like_share l_rsection">
                    {/*featured*/}
                    <div className={props.css}>
                    <p className="ft_14 roboto_medium">{props.status}</p>
                    </div>
                    {/*featured*/}
                    <div className="like_share mt_20">
                    <a href="" onClick={() => res(props.pid,props.uid)}>
                        <img src={Edit}alt={Edit} />
                    </a>
                    <a href='#' onClick={() => DeleteProd(props.pid,props.uid)}>
                        <img src={Delete} alt={Delete} />
                    </a>
                    </div>
                </div>
                <p className="ft_18 roboto_medium d_black">$ {props.price}</p>
                <p className="ft_14 roboto_regular d_lblack">2021 - 47,719 km</p>
                <p className="ft_14 roboto_regular d_elblack">
                   {props.title}...
                </p>
                <p className="ft_14 roboto_regular d_elblack">
                    <img src={location}alt="location" />
                    {props.location}
                </p>
                <p className="ft_14 roboto_regular d_elblack">
                        <img src={timer}alt="location" />
                        Expiring on: {props.date}
                    </p>
                </div>
            </div>
            </div>

    )
}

export default DashboardAdsContainer