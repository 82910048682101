import React, { useState } from 'react'
import { handleShare } from '../../../Pages/Share'
import share from '../../../images/share.svg'
import inactive from '../../../images/like_inactive.svg'
import like from '../../../images/liked.svg'
import car from '../../../images/car.jpg'
import location from '../../../images/location_light.svg'
const RelatedProduct = () => {
    const [Liked, setLiked] = useState(false);
    const isLiked = () => {
        setLiked(!Liked);
    };
    return (
        <>
           
                <div className="card_n ">
                    <img src={car} alt="car" />
                    <div className="featured">
                        <p className="ft_14 roboto_medium">Featured</p>
                    </div>
                    <div className="feature_c_details c_details mt_20">
                        {/* like share option*/}
                        <div className="like_share like_share2">
                            <a onClick={handleShare}>
                                <img src={share} alt="share" />
                            </a>
                            <a onClick={isLiked}>
                                <img src={Liked ? like : inactive} alt="Like" />
                            </a>
                        </div>
                        {/* like share option*/}
                        <p className="ft_18 roboto_medium d_black">$ 8,81,000</p>
                        <p className="ft_14 roboto_regular d_lblack">2021 - 47,719 km</p>
                        <p className="ft_14 roboto_regular d_elblack">
                            Swift Dzire EV XM, 2021...
                        </p>
                        <p className="ft_14 roboto_regular d_elblack">
                            <img src={location} alt="location" />
                            West, Ibadan
                        </p>
                    </div>
                </div>
                
        </>
    )
}

export default RelatedProduct
