import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query';
import { useQuery, useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../../Apis/config'
import axios from 'axios';
import M from "materialize-css/dist/js/materialize.min.js";
import Camera from '../../../images/camera.svg'
import Add from '../../../images/add.svg'
import Swal from 'sweetalert2';
import { Form, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const upDistricts = [
  { code: "01", name: "Agra" },
  { code: "02", name: "Aligarh" },
  { code: "03", name: "Allahabad" },
  { code: "04", name: "Ambedkar Nagar" },
  { code: "05", name: "Amethi" },
  { code: "06", name: "Amroha" },
  { code: "07", name: "Auraiya" },
  { code: "08", name: "Azamgarh" },
  { code: "09", name: "Badaun" },
  { code: "10", name: "Baghpat" },
  { code: "11", name: "Hardoi" }]
const AddProduct = (props) => {
  const handleEmailInput = async () => {
    const { value: email } = await Swal.fire({
      title: "Input email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
      allowOutsideClick: false, // Prevents closing the modal by clicking outside
    });

    if (email) {
      Swal.fire(`Entered email: ${email}`);
    } else {
      Swal.fire("No email entered");
    }
  };
        //  useEffect(() => {
        //   handleEmailInput()
        //   }, []);
  const id = cookies.get('Id');
  const Categories = cookies.get('Categories');
  const nav = useNavigate()
  const queryClient = useQueryClient();
  const redirect = (pid, type) => {
    nav(`/PostPayment/${pid}/${props.id}/${type}`)
  }
  // const Categories = queryClient.getQueryData(['Categories']);
  useEffect(() => {
    const elems = document.querySelectorAll("select");
    M.FormSelect.init(elems);
  }, []);
  const [singleFile, setSingleFile] = useState([]);
  const [imageurl, setimageurl] = useState([]);
  const [profilePic, setProfilePic] = useState(null); //
  const [errors, setErrors] = useState({});
  const uploadSingleFiles = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file); // Create a preview URL
      setSingleFile((prevFiles) => [...prevFiles, fileUrl]); // Add preview to the state
      const formData = new FormData();
      formData.append("image[0]", file);
      try {
        const response = await axios.post(`${BASE_URL}/image_upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        // console.log("Image uploaded successfully:", response.data);
        setimageurl((prevFiles) => [...prevFiles, response.data.image_name]);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  const uploadFiles = (e) => {
    e.preventDefault();
    console.log(singleFile);
  };
  const removeImage = (index) => {
    console.log("reomve");
    setSingleFile([
      ...singleFile.slice(0, index),
      ...singleFile.slice(index + 1, singleFile.length)
    ]);
    setimageurl([...imageurl.slice(0, index),
    ...imageurl.slice(index + 1, imageurl.length)])
  };
  const handleUploadClick = () => {
    document.getElementById("fileInput").click(); // Simulate file input click
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSingleFile(file)
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // update email details
  const [email, Setemail] = useState('')
  const [name, Setname] = useState('')
  const [mobile, Setmobile] = useState('')
  const [email1, Setemail1] = useState('')
  const [Cat, SetCat] = useState(false)
  const [CatVal, SetCatVal] = useState(null)
  useEffect(() => {
    fetch(`${BASE_URL}/getprofile`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: id,
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      Setemail(data.result[0].email)
      Setname(data.result[0].name)
      Setmobile(data.result[0].mobile_number)
    }).catch((err) => {
      console.log(err.message);
    })
  }, [id]);
  const [user_id, Setuser_id] = useState(id)
  const [category, Setcategory] = useState('')
  const [sub_category, Setsub_category] = useState('')
  const [title, Settitle] = useState('')
  const [description, Setdescription] = useState('')
  const [image, Setimage] = useState([])
  const [price, Setprice] = useState('')
  const [phone, Setphone] = useState('')
  const [city, Setcity] = useState('')
  const [location, Setlocation] = useState('')
  const [latitude, Setlatitude] = useState('36.6000')
  const [longitude, Setlongitude] = useState('-121.89000')
  const [tags, Settags] = useState('')
  const [make_premium, Setmake_premium] = useState('0')
  const [agree_for_term, Setagree_for_term] = useState('')
  const [negotiable, Setnegotiable] = useState('0')
  const UserDetails = {
    user_id: id,
    name: name,
    email: email1,
    mobile_number: mobile
  }
  const Updateemail = async (e) => {
    try {
      const response = await axios.post(`${BASE_URL}/updateprofile`, UserDetails, {
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
  const success = (message) => {
    Swal.fire({
      title: message,
      text: "Please wait for 24 hours to approve your item",
      icon: "success"
    });
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm({ category, title, description, singleFile, price, phone, city, location, tags, make_premium, agree_for_term, negotiable, email1 });
    setErrors(newErrors);
    if (email1 != email) {
      Updateemail()
    }
    if (Object.keys(newErrors).length <= 1) {
      // console.log(formData)
      fetch(`${BASE_URL}/insertpost`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: user_id,
          category: category,
          sub_category: sub_category,
          title: title,
          description: description,
          image: imageurl,
          price: price,
          phone: phone,
          city: city,
          location: location,
          latitude: latitude,
          longitude: longitude,
          tags: tags,
          make_premium: make_premium,
          agree_for_term: agree_for_term,
          negotiable: negotiable,
          featured: make_premium == 0 ? "0" : "1",
          urgent: make_premium == 0 ? "0" : "1",
          highlight: make_premium == "0" ? "0" : "1",
        })
      }).then((res) => {
        return res.json();
      }).then((data) => {
        if (data.status == 'success') {
          redirect(data.post_id, make_premium)
        }
        // console.log('jhfjf', data);
      }).catch((err) => {
        console.log(err.message);
      })
    }

  }
  const validateForm = (data) => {
    const errors = {};
    if (!data.category.trim()) {
      errors.category = 'category is required';
    }
    if (!data.title.trim()) {
      errors.title = 'title is required';
    } else if (data.title.length < 4) {
      errors.title = 'title must be at least 4 characters long';
    }
    if (!data.description.trim()) {
      errors.description = 'description is required';
    } else if (data.description.length < 4) {
      errors.description = 'description must be at least 4 characters long';
    }

    if (!data.price.trim()) {
      errors.price = 'Price is required';
    } else if (data.price.length < 4) {
      errors.price = 'Price must be at least 4 characters long';
    }
    if (!data.phone.trim()) {
      errors.phone = 'phone is required';
    } else if (data.phone.length < 4) {
      errors.phone = 'phone must be at least 4 characters long';
    }

    if (!data.city.trim()) {
      errors.city = 'city is required';
    }

    if (!data.location.trim()) {
      errors.location = 'location is required';
    } else if (data.location.length < 4) {
      errors.location = 'location must be at least 4 characters long';
    }
    if (!data.tags.trim()) {
      errors.tags = 'title is required';
    } else if (data.tags.length < 4) {
      errors.tags = 'title must be at least 4 characters long';
    }
    if (!data.make_premium.trim()) {
      errors.make_premium = 'Listing is required';
    }
    if (!data.singleFile) {
      errors.singleFile = 'Image is required';
    }
    else if (data.singleFile.length < 2) {
      errors.singleFile = ' 2Image is required';
    }
    if (!data.email1.trim()) {
      errors.email1 = 'Email is required';
    }
    else if (data.email1.indexOf(" ") !== -1) {
      errors.email1 = 'Space not Allowed';
    }
    return errors;
  };
  const handleCategoryChange = (e) => {
    Setcategory(e.target.value);
    fetch(`${BASE_URL}/subcategorybyid`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        category_id: e.target.value,
      })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      SetCatVal(data)
    }).catch((err) => {
      console.log(err.message);
    })
    SetCat(true); // Reset subcategory when category changes
  };

  // useEffect(() => {

  // }, [CatVal]);
  return (
    <>
      <div className="body_area grid_main">
        <div className='left_a'>
          <p className='ft_18 roboto_medium d_black mt_20'>Post An Advertise</p>
          <form >
            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Categories</p>
              <select name='category' value={category} onChange={handleCategoryChange} >
                <option value="" disabled selected>Select Categories</option>
                {Categories && Categories.map((option) => (
                  <option key={option.id} value={option.id} data-icon={option.picture}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.category && (
                <span className="error-message">
                  {errors.category}
                </span>
              )}
            
            </div>
            {CatVal&& (
        <>
          
        </>
      )}
            {category&&
             <div class="input-field col s12 ">
            <select name='sub_category' value={sub_category} style={{display:'block'}} onChange={(e) => Setsub_category(e.target.value)} >
            <option value>Select Sub Category</option>
            {CatVal && CatVal.map((subcat, index) => {
                  console.log("SAJID : ",index,subcat);
                  return (
                    <> 
                 <option value={subcat.id} >{subcat.name}</option>
                    </>
                  )
                })}
               </select>
               </div>
}

            {/* <div class="input-field col s12 ">
                        <select class="icons">
                            <option value="" disabled selected>Select Sub Category</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                            <option value="" data-icon="images/sample-1.jpg">example 1</option>
                        </select>
                    </div> */}

            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Title</p>
              <input placeholder="Enter title for your listing..." id="first_name" type="text" class="validate" name="title" value={title} onChange={(e) => Settitle(e.target.value)} />
              {errors.title && (
                <span className="error-message">
                  {errors.title}
                </span>
              )}
            </div>

            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Description</p>
              <textarea class="review_area mt_20 ft_14" placeholder="Include condition, features and reason for selling..." name='description' value={description} onChange={(e) => Setdescription(e.target.value)}></textarea>
              {errors.description && (
                <span className="error-message">
                  {errors.description}
                </span>
              )}
            </div>


            <p className='ft_14 roboto_medium d_black mt_10'>Upload pictures upto 10</p>
            <div class="img_uplo mt_10">
              {singleFile.length !== 0 &&
                singleFile.map((url, index) => (
                  <>
                    <div class="circle22 p_image" key={url}>
                      <img class="profile-pic" src={url} onClick={handleUploadClick}></img>
                      <span
                        className="remove_img"
                        onClick={() => removeImage(index)}>
                        X
                      </span>
                    </div>
                  </>
                ))}
              {singleFile.length > 8 ? null : (
                <>
                  <div class="p_image" onClick={handleUploadClick}>
                    <img class="fa fa-camera upload-button" src={Camera} ></img>
                    <input class="file-upload" type="file" accept="image/*" id="fileInput" onChange={uploadSingleFiles} />
                    <p className='ft_14 d_black roboto_medium'>Add Photo</p>
                  </div>
                </>
              )}
              {errors.singleFile && (
                <span className="error-message">
                  {errors.singleFile}
                </span>
              )}
            </div>

            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Price</p>
              <input placeholder="Set a price" id="first_name" type="number" class="validate" name='price' value={price} onChange={(e) => Setprice(e.target.value)} />
              {errors.price && (
                <span className="error-message">
                  {errors.price}
                </span>
              )}
            </div>
            <label>
              <input type="checkbox" />
              <span name='negotiable' onClick={() => Setnegotiable(1)}>Negotiable</span>
            </label>
            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Phone Number*</p>
              <input id="icon_telephone" type="number" class="validate" name='phone' value={phone} onChange={(e) => Setphone(e.target.value)} />
              {errors.phone && (
                <span className="error-message">
                  {errors.phone}
                </span>
              )}
            </div>
            <div class="input-field col s12">
              <p className='ft_14 roboto_medium d_black mt_10'>Email*</p>
              {email && <input id="icon_telephone" type="email" class="validate" name='email' value={email} onChange={(e) => Setemail(e.target.value)} disabled />
              }

              {!email && <div>
                <input id="icon_telephone" type="email" class="validate" name='email1' value={email1} onChange={(e) => Setemail1(e.target.value)} required />
                {errors.email1 && (
                  <span className="error-message">
                    {errors.email1}
                  </span>
                )}
              </div>
              }
            </div>
            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>City</p>
              <select name='city' value={city} onChange={(e) => Setcity(e.target.value)} >
                <option value="" disabled selected>Select City</option>
                <option value="1234">Delhi</option>
                <option value="3522">Noida</option>
                <option value="3534">Agra</option>
                <option value="3556">Lucknow</option>
                <option value="3578">Jaipur</option>
                <option value="2451">Hardoi</option>
              </select>
              {errors.city && (
                <span className="error-message">
                  {errors.city}
                </span>
              )}
            </div>

            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Address</p>
              <input id="icon_telephone" type="text" class="validate" name='location' value={location} onChange={(e) => Setlocation(e.target.value)} />
              {errors.location && (
                <span className="error-message">
                  {errors.location}
                </span>
              )}
            </div>

            <div class="input-field col s12 ">
              <p className='ft_14 roboto_medium d_black mt_10'>Tags</p>
              <input id="icon_telephone" type="text" class="validate" name='tags' value={tags} onChange={(e) => Settags(e.target.value)} />
              {errors.tags && (
                <span className="error-message">
                  {errors.tags}
                </span>
              )}
            </div>


            {/* <!--premium --> */}
            <p className='ft_14 roboto_medium d_black mt_10'>Select Plan</p>
            <div className='d_flex l_gap mt_10'>
              <div className='listing_charges'>
                <label>
                  <input name="make_premium" type="radio" value="0"
                    checked={make_premium === "0"} onChange={(e) => Setmake_premium(e.target.value)} />
                  <span>Normal Listing</span>
                </label>
                <p className='ft_14 roboto_medium d_elblack '>Normal Charges: 0.1 Usd</p>
              </div>
              <div className='listing_charges'>
                <label>
                  <input name="make_premium" type="radio" value="1" checked={make_premium === "1"} onChange={(e) => Setmake_premium(e.target.value)} />
                  <span>Premium Listing</span>
                </label>
                <p className='ft_14 roboto_medium d_elblack '>Premium  Charges: 0.2 Usd</p>
              </div>
              {errors.make_premium && (
                <span className="error-message">
                  {errors.make_premium}
                </span>
              )}
            </div>
            <div class="mt_20">
              <label>
                <input type="checkbox" />
                <span name='agree_for_term' onClick={() => Setagree_for_term(1)}>I have read and agree to the Terms & Conditions</span>
              </label>
              {errors.agree_for_term && (
                <span className="error-message">
                  {errors.agree_for_term}
                </span>
              )}
            </div>
            <button class="mt_20 btn_full pr_btn roboto_regular waves-effect ft_14 mt_10" onClick={onSubmit}>
              <img src={Add} alt=" " className='btn_img' />
              Post an AD
            </button>
          </form>

        </div>
        <div className='right_a'></div>

      </div>
    </>
  )
}

export default AddProduct
